export const columns = [
    { field: 'fundFamily', headerName: 'Fund Family' },
    {
        field: 'peerGroup',
        headerName: 'Peer Group',
    },
    {
        field: 'action',
        headerName: 'Action',
    },
];
