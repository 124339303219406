import { FormControlLabel, Switch } from "@mui/material";
import React, { useState } from "react";
import { Controller, Control } from "react-hook-form";

interface ToggleProps {
    control?: Control<any>;
    name?: string;
    labelName?: string;
    onChange?: (checked: boolean) => void;
    disabled?: boolean;
    labelPlacement?: "end" | "start" | "top" | "bottom";
    checked?: boolean;
}

function Toggle({
                    control,
                    name,
                    labelName,
                    disabled = false,
                    onChange,
                    labelPlacement = "top",
                    checked,
                }: ToggleProps) {
    const [internalChecked, setInternalChecked] = useState(checked || false);

    const handleChange = (isChecked: boolean) => {
        setInternalChecked(isChecked);
        onChange?.(isChecked);
    };

    return control && name ? (
        <FormControlLabel
            control={
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <Switch
                            {...field}
                            checked={field.value}
                            onChange={(event) => {
                                const isChecked = event.target.checked;
                                field.onChange(isChecked);
                                onChange?.(isChecked);
                            }}
                            disabled={disabled}
                        />
                    )}
                />
            }
            label={labelName || ""}
            labelPlacement={labelPlacement}
        />
    ) : (
        <FormControlLabel
            control={
                <Switch
                    checked={internalChecked}
                    onChange={(event) => handleChange(event.target.checked)}
                    disabled={disabled}
                />
            }
            label={labelName || ""}
            labelPlacement={labelPlacement}
        />
    );
}

export default Toggle;