import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {IFormInputs, Rows} from '../../../types/shared';
import {Box, CircularProgress, Grid} from '@mui/material';
import OptionSelect from '../../../components/option-select/option-select';
import {useDataProcessingContext} from '../../../context/data-processing-context';
import {useUIControlContext} from '../../../context/ui-control-context';
import {columns} from '../../../data/columns/research-list/data-for-columns';
import Toggle from '../../../components/toggle/toggle';
import SimpleBackdrop from '../../../components/backdrop/backdrop';
import InfoPage from '../../info/info-page';
import CustomTable from '../../../components/custom-table/custom-table';
import {useNavigate} from 'react-router-dom';
import TextInput from '../../../components/text-input/text-input';
import {Funds} from '../../../types/research-list';
import axios from 'axios';
import Loading from '../../loading/loading';
import {useSharedDataContext} from '../../../context/shared-data-context';
import CustomButton from '../../../components/custom-button/custom-button';
import {useStore} from '../../../store';
import dayjs from 'dayjs';
import CustomDialog from '../../../components/custom-dialog/custom-dialog';
import Toast from '../../../components/toast/toast';
import Stack from '@mui/material/Stack';
import {ActionData} from "../../../types/custom-table";

function ResearchList() {
    document.title = `AppHub - Dqr - Research List`;
    const accessToken = useStore((state) => state.accessToken);
    const [researchListData, setResearchListsData] = useState<Funds[]>([]);
    const [loadingResaerchList, setLoadingResaerchList] =
        useState<boolean>(false);
    const [filteredResearchListData, setFilteredResearchListData] = useState<
        Funds[]
    >([]);

    const {selected, setSelected} = useDataProcessingContext();
    const {
        openBackdrop,
        handleLogOut,
        loading,
        setLoading,
        alertSeverity,
        alertMessage,
        openToast,
        handleCloseToast,
        isDialogOpen,
        handleOpenDialog,
        handleCloseDialog,
        handleManageToast,
    } = useUIControlContext();

    const {
        peerGroupData,
        peerGroupNames,
        afbStatusData,
        afbStatusNames,
        handleGetPeerGroup,
        baseUrl,
        handleGetAfbStatusGroup,
    } = useSharedDataContext();

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: {errors},
    } = useForm<IFormInputs>({
        defaultValues: {
            family: 'Fund Family',
            peerGroup: '',
            afbStatus: '',
            analyst: 'Example',
            analystSelectionFunds: false,
            searchValue: '',
            entityName: '',
            entityIsin: '',
        },
    });
    const peerGroupName = watch('peerGroup');
    const afbStatusName = watch('afbStatus');
    const analystSelectionFunds = watch('analystSelectionFunds');

    const navigate = useNavigate();
    const handleNavigate = useCallback(
        (id: string | number) => {
            if(id != undefined)
                navigate(`/dqr/research-list/${id}`);
        },
        [navigate]
    );

    const handleGetPeerGroupId = useCallback(
        (peerGroupName: string) => {
            if (peerGroupData) {
                return peerGroupData.find(
                    (peer) => peer?.name === peerGroupName
                )?.id;
            }
        },
        [peerGroupData]
    );

    const handleGetAfbStatusId = useCallback(
        (afbStatusName: string) => {
            if (afbStatusData) {
                return afbStatusData.find(
                    (status) => status?.name === afbStatusName
                )?.id;
            }
        },
        [afbStatusData]
    );

    const handleFilterResearchData = useCallback(() => {
        const peerGroupId = peerGroupName
            ? handleGetPeerGroupId(peerGroupName)
            : null;
        console.log(peerGroupId)
        const afbStatusId = afbStatusName
            ? handleGetAfbStatusId(afbStatusName)
            : null;

        const analystSelectionFundsFilteredData = analystSelectionFunds
            ? researchListData.filter((fund) => fund.isAnalystSelection)
            : researchListData;

        if (!peerGroupName && !afbStatusName) {
            console.log("if)")
            setFilteredResearchListData(analystSelectionFundsFilteredData);
        } else {
            const filteredData = analystSelectionFundsFilteredData.filter((fund) => {
                const matchesPeerGroup =
                    peerGroupId !== null
                        ? fund.peerGroupId === peerGroupId
                        : true;
                const matchesAfbStatus =
                    afbStatusId !== null
                        ? fund.status_Id === afbStatusId
                        : true;
                return matchesPeerGroup && matchesAfbStatus;
            });

            setFilteredResearchListData(filteredData);
        }
    }, [
        peerGroupName,
        afbStatusName,
        analystSelectionFunds,
        researchListData,
        handleGetPeerGroupId,
        handleGetAfbStatusId,
    ]);

    const selectData = useMemo(
        () => [
            {
                control: control,
                name: 'peerGroup',
                data: peerGroupNames,
                labelId: 'select-peer-group-label',
                label: true,
                selectId: 'select-peer-group',
                labelName: 'Peer Group',
            },
        ],
        [control, peerGroupNames, afbStatusNames]
    );

    const handleCreateFundWithConnect = useCallback(
        async (
            fundFamilyId?: number
        ) => {
            console.log(fundFamilyId)
            setLoading(true);
            try {
                const dataCreateEntity = {
                    family_Id: fundFamilyId,
                };
                const useEndpoint = `${baseUrl}Fund`;
                const response = await axios.post(`${useEndpoint}`,
                    dataCreateEntity,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

                if (response) {
                    handleCloseDialog();
                    navigate(`/dqr/research-list/${response.data?.data?.id}`);
                    handleManageToast(
                        'success',
                        'Fund created successfully.'
                    );
                }
            } catch (error) {
                const typedError = error as { response?: { status?: number } };

                if (typedError.response?.status === 401) {
                    handleLogOut();
                } else if (typedError.response?.status === 409) {
                    handleManageToast('error', 'Fund or Isin already exists.');
                } else {
                    handleManageToast(
                        'error',
                        'Error creating fund. Please try again.'
                    );
                }
            } finally {
                setLoading(false);
            }
        },
        [navigate]
    );

    const rows: (Rows & { actions?: ActionData[] | undefined })[] = useMemo(() => {
        if (!peerGroupData || !filteredResearchListData) return [];
        console.log("Building table rows", { peerGroupData, filteredResearchListData });
        return filteredResearchListData.map(({ name, peerGroupId, id, family_Id }) => {
            const peerGroupName = peerGroupData.find(
                (peer) => Number(peer.id) === Number(peerGroupId)
            )?.name || "Unknown Peer Group";

            console.log(`Row - Fund: ${name}, PeerGroup_Id: ${peerGroupId}, Found: ${peerGroupName}`);

            return {
                id,
                familyId: family_Id,
                fundFamily: name,
                peerGroup: peerGroupName,
                actions: id
                    ? undefined
                    : [
                        {
                            status: 'NoResults',
                            color: 'warning',
                            buttonText: 'Create',
                            actionType: 'reportListAction',
                            actionEvent: () => handleCreateFundWithConnect(family_Id),
                        },
                    ],
            };
        });
    }, [filteredResearchListData, peerGroupData, handleCreateFundWithConnect]);

    const handleClearFilter = useCallback(() => {
        reset();
    }, [reset]);

    const onSubmitSearchFund: SubmitHandler<IFormInputs> = useCallback(
        async ({searchValue}) => {
            const url = `${baseUrl}Fund/Filter`;
            const data = {
                SearchText: searchValue?.toUpperCase(),
                pageSize: 500
            };
            setLoading(true);
            try {
                const response = await axios.post(url, data, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                setResearchListsData(response?.data?.data?.data);
                if (response?.data?.data?.data.length === 0) {
                    handleManageToast(
                        'error',
                        'There are no results with that criteria'
                    );
                }
                console.log('response research list data', response);
            } catch (error) {
                console.error('Error research list request:', error);
                handleManageToast(
                    'error',
                    'There was an error trying to search for the fund'
                );
                const typedError = error as { response?: { status?: number } };

                if (typedError.response?.status === 401) {
                    handleLogOut();
                }
            } finally {
                setLoading(false);
            }
        },
        [
            reset,
            setLoading,
            baseUrl,
            accessToken,
            handleManageToast,
            setResearchListsData,
            handleLogOut,
        ]
    );

    useEffect(() => {
        setLoadingResaerchList(true);
        handleGetPeerGroup();
        handleGetAfbStatusGroup();
        setLoadingResaerchList(false);
    }, [handleGetPeerGroup, handleGetAfbStatusGroup, setLoadingResaerchList]);

    useEffect(() => {
        handleFilterResearchData();
    }, [handleFilterResearchData]);
    useEffect(() => {
        if (!isDialogOpen) reset();
    }, [isDialogOpen, reset]);

    if (loadingResaerchList) {
        return <Loading/>;
    }

    return (
        <>
            <Box
                component={isDialogOpen ? 'section' : 'form'}
                onSubmit={handleSubmit(onSubmitSearchFund)}
                sx={{width: '100%'}}
            >
                <Grid display='flex' gap={2} flexWrap={'wrap'}>
                    {selectData?.map(
                        ({
                             control,
                             name,
                             data,
                             labelId,
                             label,
                             selectId,
                             labelName,
                         }) => {
                            const sortedData = [...data].sort((a, b) => a.localeCompare(b));
                            return (
                                <Grid item sx={{width: '180px'}} key={name}>
                                    <OptionSelect
                                        control={control}
                                        name={name}
                                        data={sortedData}
                                        labelId={labelId}
                                        label={label}
                                        selectId={selectId}
                                        labelName={labelName}
                                    />
                                </Grid>
                            );
                        }
                    )}

                    <Toggle
                        control={control}
                        name='analystSelectionFunds'
                        labelName='View only Analyst Selection funds'
                    />

                    <CustomButton
                        onClick={handleClearFilter}
                        type='button'
                        color='warning'
                        variant='contained'
                        size='large'
                        text={'Clear filters'}
                    />
                </Grid>
                <Grid
                    flexWrap={'nowrap'}
                    container
                    gap={1}
                    sx={{
                        width: '100%',
                        marginBottom: -1,
                        marginTop: 2,
                    }}
                >
                    <Grid item sx={{flexGrow: 1}}>
                        <TextInput
                            name='searchValue'
                            control={control}
                            labelName='Search by name or ISIN'
                            ruleRequired={!isDialogOpen}
                            minLengthErrorValue={3}
                            minLengthError='Must be at least 3 characters'
                        />
                    </Grid>
                    <Grid item>
                        <CustomButton
                            type='submit'
                            color='success'
                            variant='contained'
                            size='large'
                            text={'Search'}
                        />
                    </Grid>
                </Grid>
                <Grid container gap={2} flexDirection='column'>
                    {loading && !isDialogOpen && <Loading/>}
                    {filteredResearchListData.length > 0 ? (
                        <Grid item container gap={2}>
                            {filteredResearchListData &&
                                filteredResearchListData.length > 0 && (
                                    <Grid
                                        item
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <CustomTable
                                            handleNavigate={
                                                handleNavigate
                                            }
                                            useCheckbox={false}
                                            selected={selected}
                                            setSelected={setSelected}
                                            columns={columns}
                                            rows={rows}
                                        />
                                    </Grid>
                                )}
                        </Grid>
                    ) : (
                        <InfoPage
                            message={
                                'The are no funds to show, search for one'
                            }
                        />
                    )}
                </Grid>
            </Box>

            <SimpleBackdrop
                openBackdrop={openBackdrop as boolean}
            />
            <Toast
                handleCloseToast={handleCloseToast as () => void}
                openToast={openToast as boolean}
                severity={alertSeverity}
                message={alertMessage}
            />
        </>
    );
}

export default ResearchList;
