import {Control} from "react-hook-form";
import {Data, IFormInputs} from "../../../../types/shared";
import React from "react";
import {Box, Grid} from "@mui/material";
import CustomTextarea from "../../../../components/custom-textarea/custom-textarea";

interface Props {
    readonly control: Control<IFormInputs>;
    readonly textareaData: Data[];
}

function Description({control, textareaData}: Props) {

    return (
        <Box component={'article'} sx={{width: '100%'}} marginTop={'30px'}>
            {textareaData.map(
                ({control, name, labelName, updateDate, updateName}) => (
                    <Grid container item marginBottom={'20px'} key={name}>
                        <CustomTextarea
                            name={name}
                            control={control}
                            labelName={labelName}
                            updateDate={updateDate}
                            updateName={updateName}
                        />
                    </Grid>
                )
            )}
        </Box>
    );
}

export default Description;