import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import dayjs from 'dayjs';
import {IFormInputs, Translation} from '../../../types/shared';
import GeneralInformation from './general-information/general-information';
import Description from './description/description';
import Team from './team/team';
import Assessment from './assessment/assessment';
import InvestmentProcess from './investment-process/investment-process';
import {Box} from '@mui/system';
import TabComponent from '../../../components/tab/tab';
import {CircularProgress, Divider, Grid} from '@mui/material';
import {dataForSelect} from '../../../data/research-list/general-information/data-for-select';
import axios, {AxiosError} from 'axios';
import {Benchmark, ISIN} from '../../../types/research-detail';
import {EsgTypes, Fund, PeerGroup} from '../../../types/research-list';
import {useUIControlContext} from '../../../context/ui-control-context';
import Loading from '../../loading/loading';
import {useSharedDataContext} from '../../../context/shared-data-context';
import OptionSelect from '../../../components/option-select/option-select';
import {useNavigate, useParams} from 'react-router-dom';
import {useStore} from '../../../store';
import {ManagerData} from '../../../types/risk-detail';
import Toast from '../../../components/toast/toast';
import SimpleBackdrop from '../../../components/backdrop/backdrop';
import CustomButton from '../../../components/custom-button/custom-button';
import Esg from './esg/esg';
import Isins from './isins/isins';
import TextInput from '../../../components/text-input/text-input';
import CustomDialog from '../../../components/custom-dialog/custom-dialog';
import {useDataProcessingContext} from '../../../context/data-processing-context';
import {flattenEsgData} from "./esg/mapping";
import Typography from "@mui/material/Typography";

function ResearchDetail() {
    const {researchId} = useParams();
    const [accessToken] = useStore((state) => [state.accessToken]);
    const {selected, setSelected} = useDataProcessingContext();
    const {
        alertSeverity,
        alertMessage,
        openToast,
        handleCloseToast,
        openBackdrop,
        handleOpenBackdrop,
        handleManageToast,
        handleCloseBackdrop,
        handleCloseDialog,
        loading,
        setLoading,
        handleLogOut,
        isDialogOpen,
        handleOpenDialog,
        refetchData,
        setRefetchData,
    } = useUIControlContext();
    const [researchData, setResearchData] = useState<Fund>();
    const [esgData, setEsgData] = useState<EsgTypes>();
    const [esgDataId, setEsgDataId] = useState<string>();
    const [managerData, setManagerData] = useState<ManagerData[]>([]);
    const [managerDataNames, setManagerDataNames] = useState<string[]>([]);
    const [managerMap, setManagerMap] = useState<Record<string, string>>({});
    const [isinsData, setIsinsData] = useState<ISIN[]>([]);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [isDialogOpenReview, setIsDialogOpenReview] = useState(false);
    const [underReviewOld, setUnderReviewOld] = useState(false)
    const [idPeerGroup, setIdPeerGroup] = useState<number | undefined>();
    const [restrictedOld, setRestrictedOld] = useState(false);
    const [analystSelectionOld, setAnalystSelectionOld] = useState(false);
    const {
        peerGroupData,
        peerGroupNames,
        handleTransformBooleanValue,
        handleGetLanguageIdFromSelect,
        handleGetLanguageItem,
        handleGetPeerGroup,
        handleGetAfbStatusGroup,
        afbStatusData,
        afbStatusNames,
        handleTransformToBooleanValue,
        baseUrl,
        newId,
        handleGetLanguages,
        languagesData,
    } = useSharedDataContext();

    const [tab, setTab] = useState('GENERAL INFORMATION');
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        reset,
        formState: {errors},
    } = useForm<IFormInputs>({
        defaultValues: {
            languages: 'English',
            fundIsin: '',
            fundFamilyName: '',
            view: '',
            bkbRisikoklasse: '',
            bkbRetro: '',
            bkbEsg: '',
            bkbTyp: '',
            bkbQa: '',
            blkbEsgKat: '',
            shkbGkNumber: '',
            slsStatus: '',
            slsRisikotyp: '',
            slsFw: '',
            belongsToInsightList: '',
            applyAllFamilyFunds: true,
            factsheetDate: dayjs(),
            manager: '',
            peerGroupGeneralInfo: '',
            benchmark: '',
            afbStatusGeneralInfo: '',
            underReview: false,
            restrictedToggle: false,
            analystSelectionToggle: false,
            notes: '',
            fundDescription: '',
            teamStars: 3,
            team: '',
            investmentProcessStars: 2,
            investmentProcess: '',
            assessment: '',
            searchValue: '',
            comment: '',
            file: [],
            esgData: ''
        },
    });
    const languagesValue = watch('languages');
    const searchValue = watch('searchValue');

    const fundDescriptionLastUpdate = watch('fundDescriptionLastUpdate');
    const fundDescriptionLastUpdateName = watch('fundDescriptionLastUpdateName');
    const notesLastUpdate = watch('notesLastUpdate');
    const notesLastUpdateName = watch('notesLastUpdateName');
    const teamLastUpdate = watch('teamLastUpdate');
    const teamLastUpdateName = watch('teamLastUpdateName');

    const investmentProcessLastUpdate = watch('investmentProcessLastUpdate');
    const investmentProcessUpdateName = watch('investmentProcessUpdateName');

    const assessmentLastUpdate = watch('assessmentLastUpdate');
    const assessmentLastUpdateName = watch('assessmentLastUpdateName');

    const peerGroupValue = watch('peerGroupGeneralInfo');
    const underReviewToggle = watch('underReview');
    const restrictedToggle = watch('restrictedToggle');
    const analystSelectionToggle = watch('analystSelectionToggle');

    const handleOpenDialogConditional = () => {
        console.log(underReviewToggle + '' + underReviewOld);
        console.log(restrictedToggle + '' + restrictedOld);
        console.log(analystSelectionToggle + '' + analystSelectionOld);
        if (underReviewToggle != underReviewOld || restrictedToggle != restrictedOld || analystSelectionToggle != analystSelectionOld) {
            setIsDialogOpenReview(true);
        } else {
            handleSubmit(onSubmit)();
        }
    };

    const handleCloseDialogReview = () => {
        setIsDialogOpenReview(false);
    };

    const handleChangeTab = useCallback(
        (event: React.SyntheticEvent, newValue: string) => {
            setTab(newValue);
        },
        [setTab]
    );

    const fetchResearchData = useCallback(
        async (id: string) => {
            setLoading(true);
            try {

                const [researchResponse, esgResponse] = await Promise.all([
                    axios.get(`${baseUrl}Fund/${researchId}`, {headers: {Authorization: `Bearer ${accessToken}`}}),
                    axios.get(`${baseUrl}Fund/${researchId}/esg`, {headers: {Authorization: `Bearer ${accessToken}`}})
                ]);
                const fundModification = {
                    ...researchResponse?.data?.data?.fundModification,
                    manager_Id: researchResponse?.data?.data?.fund?.managerId,
                };
                setResearchData(fundModification);
                setEsgData(esgResponse?.data?.data?.fundModification);
                setEsgDataId(esgResponse?.data?.data?.fundModification.id);
                setUnderReviewOld(researchResponse?.data.data.fundModification?.afbStatus_underReview?.value);
                setRestrictedOld(researchResponse?.data.data.fundModification?.isRestricted?.value);
                setAnalystSelectionOld(researchResponse?.data.data.fundModification?.isAnalystSelection?.value);
                setIdPeerGroup(researchResponse?.data.data.fund.peerGroupId);
                const isinsList = researchResponse?.data?.data?.fund?.fundIsin;
                setIsinsData(isinsList);
            } catch (error) {
                console.log(error)
                const typedError = error as { response?: { status?: number } };
                if (typedError.response?.status === 401) {
                    handleLogOut();
                } else if (typedError.response?.status === 400) {
                    handleManageToast('error', 'Check the different fields.');
                } else {
                    handleManageToast(
                        'error',
                        'Error saving data. Please try again.'
                    );
                }
            } finally {
                setLoading(false);
            }
        },
        [
            setLoading,
            baseUrl,
            accessToken,
            setResearchData,
            handleLogOut,
            setIsinsData,
            setEsgData
        ]
    );

    const handleCreateISIN: SubmitHandler<IFormInputs> = useCallback(
        async ({fundIsin}) => {
            setLoading(true);
            try {
                const dataCreateIsin = {
                    ISIN: fundIsin,
                    Fund_Id: researchId,
                };

                const response = await axios.post(
                    `${baseUrl}Fund/${researchId}/Isin`,
                    dataCreateIsin,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

                if (response) {
                    handleManageToast('success', 'ISIN created successfully.');
                }

                setRefetchData((prev) => !prev);
                handleCloseDialog();
                setLoading(false);
            } catch (error) {
                setLoading(false);
                const typedError = error as { response?: { status?: number } };

                if (typedError.response?.status === 401) {
                    handleLogOut();
                } else if (typedError.response?.status === 400) {
                    handleManageToast('error', 'ISIN already exists.');
                } else {
                    handleManageToast(
                        'error',
                        'Error creating ISIN. Please try again.'
                    );
                }
            } finally {
                reset();
            }
        },
        [
            reset,
            setRefetchData,
            accessToken,
            setLoading,
            handleLogOut,
            handleCloseDialog,
            handleManageToast,
            baseUrl,
        ]
    );

    const fetchManagerData = useCallback(async () => {
        try {
            const response = await axios.get(`${baseUrl}Manager`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response) {
                const normalizedData = [
                    {id: "", name: "--- Empty ---"},
                    ...response.data.data,
                ];
                setManagerData(normalizedData);

                const managerNames = normalizedData.map((manager) => manager.name); // Extraemos solo los nombres
                setManagerDataNames(managerNames);

                const map = normalizedData.reduce((acc: Record<string, string>, manager: ManagerData) => {
                    acc[manager.name] = manager.id;
                    return acc;
                }, {});
                setManagerMap(map);
            }
        } catch (error) {
            const typedError = error as { response?: { status?: number } };
            if (typedError.response?.status === 401) {
                handleLogOut();
            }
        }
    }, [baseUrl, accessToken, handleLogOut]);

    const handleGetManagerName = useCallback(
        (managerId: string | null) => {
            if (managerData) {
                return managerData.find((manager) => manager?.id === managerId)
                    ?.name;
            }
        },
        [managerData]
    );

    const handleGetPeerGroupName = useCallback(
        (peerGroupId: number) => {
            if (peerGroupData) {
                return peerGroupData.find((peer) => peer?.id === peerGroupId)
                    ?.name;
            }
        },
        [peerGroupData]
    );

    const handleGetAfbStatusName = useCallback(
        (afbStatusId: number) => {
            if (afbStatusData) {
                return afbStatusData.find(
                    (status) => status?.id === afbStatusId
                )?.name;
            }
        },
        [afbStatusData]
    );

    interface ErrorMessage {
        message: string;
        type: string;
    }

    interface ErrorData {
        messages: ErrorMessage[];
    }

    const navigate = useNavigate();

    const selectData = useMemo(
        () => [
            {
                control: control,
                name: 'manager',
                data: managerDataNames,
                labelId: 'select-manager-label',
                label: true,
                selectId: 'select-manager',
                labelName: 'Manager',
            },
            {
                control: control,
                name: 'peerGroupGeneralInfo',
                data: peerGroupNames,
                labelId: 'select-peerGroupGeneralInfo-label',
                label: true,
                selectId: 'select-peer-group-general-info',
                labelName: 'Peer Group',
                disabled: true,
            }
        ],
        [
            control,
            peerGroupNames,
            dataForSelect,
            afbStatusNames,
            managerDataNames,
        ]
    );

    const textInputData = useMemo(
        () => [
            {
                control: control,
                name: 'fundFamilyName',
                labelName: 'Fund Family Name',
                isDisabled: true
            },
        ],
        [control]
    );

    const viewTextInputData = useMemo(
        () => ({
            control: control,
            name: 'view',
            labelName: 'View',
        }),
        [control]
    );

    const swissTextInputsFields = useMemo(
        () => [
            {
                control: control,
                name: 'bkbRisikoklasse',
                labelName: 'BKB Risikoklasse',
            },
            {
                control: control,
                name: 'bkbRetro',
                labelName: 'BKB Retro',
            },
            {
                control: control,
                name: 'bkbEsg',
                labelName: 'BKB ESG',
            },
            {
                control: control,
                name: 'bkbTyp',
                labelName: 'BKB Typ',
            },
            {
                control: control,
                name: 'bkbQa',
                labelName: 'BKB QA',
            },
            {
                control: control,
                name: 'blkbEsgKat',
                labelName: 'BLKB ESG Kat',
            },
            {
                control: control,
                name: 'shkbGkNumber',
                labelName: 'SHKB GK-Number',
            },
            {
                control: control,
                name: 'slsStatus',
                labelName: 'SLS Status',
            },
            {
                control: control,
                name: 'slsRisikotyp',
                labelName: 'SLS Risikotyp',
            },
            {
                control: control,
                name: 'slsFw',
                labelName: 'SLS FW',
            },
        ],
        [control]
    );

    const toggleData = useMemo(
        () => [
            {
                control: control,
                name: 'underReview',
                labelName: 'Under Review',
            },
            {
                control: control,
                name: 'restrictedToggle',
                labelName: 'Restricted',
            },
            {
                control: control,
                name: 'analystSelectionToggle',
                labelName: 'Analyst Selection',
            },
        ],
        [control]
    );

    const textareaData = useMemo(
        () => [
            {
                control: control,
                name: 'notes',
                labelName: 'Notes',
                updateName: notesLastUpdateName,
                updateDate: notesLastUpdate,
            },
        ],
        [control, notesLastUpdateName, notesLastUpdate]
    );

    const descriptionTextAreaData = useMemo(
        () => [
            {
                control: control,
                name: 'fundDescription',
                labelName: 'Fund Description',
                updateName: fundDescriptionLastUpdateName,
                updateDate: fundDescriptionLastUpdate,
            },
        ],
        [control, fundDescriptionLastUpdateName, fundDescriptionLastUpdate]
    );

    const handleChangeSectionByTab = useCallback(() => {
        if (tab === 'GENERAL INFORMATION') {
            return (
                <GeneralInformation
                    control={control}
                    selectData={selectData}
                    textInputData={textInputData}
                    viewInputData={viewTextInputData}
                    swissTextInputFields={swissTextInputsFields}
                    toggleData={toggleData}
                    textareaData={textareaData}
                    researchId={researchId}
                />
            );
        } else if (tab === 'DESCRIPTION') {
            return (
                <Description
                    control={control}
                    textareaData={descriptionTextAreaData}
                />
            );
        } else if (tab === 'TEAM') {
            return (
                <Team
                    control={control}
                    teamLastUpdate={teamLastUpdate}
                    teamLastUpdateName={teamLastUpdateName}
                />
            );
        } else if (tab === 'INVESTMENT PROCESS') {
            return (
                <InvestmentProcess
                    control={control}
                    investmentProcessUpdateName={investmentProcessUpdateName}
                    investmentProcessLastUpdate={investmentProcessLastUpdate}
                />
            );
        } else if (tab === 'ASSESSMENT') {
            return (
                <Assessment
                    control={control}
                    assessmentLastUpdateName={assessmentLastUpdateName}
                    assessmentLastUpdate={assessmentLastUpdate}
                />
            );
        } else if (tab === 'EXCLUSIONS') {
            return (
                <Esg
                    control={control}
                    esgData={esgData}
                />
            );
        } else if (tab === 'ISINS') {
            return (
                <Isins
                    selected={selected}
                    setSelected={setSelected}
                    data={isinsData}
                    searchValue={searchValue}
                    control={control}
                />
            );
        }
    }, [
        selected,
        setSelected,
        searchValue,
        tab,
        handleOpenDialog,
        control,
        selectData,
        textInputData,
        toggleData,
        textareaData,
        teamLastUpdate,
        teamLastUpdateName,
        investmentProcessLastUpdate,
        investmentProcessUpdateName,
        assessmentLastUpdate,
        assessmentLastUpdateName,
        isinsData,
        esgData
    ]);

    const tabsArray = [
        {
            tabValue: 'GENERAL INFORMATION',
            tabLabel: 'General information',
        },
        {
            tabValue: 'DESCRIPTION',
            tabLabel: 'Description',
        },
        {
            tabValue: 'TEAM',
            tabLabel: 'Team',
        },
        {
            tabValue: 'INVESTMENT PROCESS',
            tabLabel: 'Investment process',
        },
        {
            tabValue: 'ASSESSMENT',
            tabLabel: 'Assessment',
        },
        {
            tabValue: 'EXCLUSIONS',
            tabLabel: 'EXCLUSIONS',
        },
        {
            tabValue: 'ISINS',
            tabLabel: 'ISINS',
        },
    ];

    const handleGetIdPeerGroup = useCallback(
        (data: PeerGroup[] | null, name: string): number | undefined => {
            if (!data) return undefined;

            return data.find((item) => item.name === name)?.id as number;
        },
        []
    );

    const onSubmit: SubmitHandler<IFormInputs> = useCallback(
        async ({
                   fundFamilyName,
                   view,
                   bkbRisikoklasse,
                   bkbRetro,
                   bkbEsg,
                   bkbTyp,
                   bkbQa,
                   blkbEsgKat,
                   shkbGkNumber,
                   slsStatus,
                   slsRisikotyp,
                   slsFw,
                   belongsToInsightList,
                   applyAllFamilyFunds,
                   factsheetDate,
                   manager,
                   peerGroupGeneralInfo,
                   underReview,
                   restrictedToggle,
                   analystSelectionToggle,
                   notes,
                   fundDescription,
                   teamStars,
                   team,
                   investmentProcessStars,
                   investmentProcess,
                   assessment,
                   comment,
                   file,
                   esgData
               }) => {
            const apiUrlFund = `${baseUrl}Fund/${researchId}`;
            const apiUrlFundEsg = `${baseUrl}Fund/${researchId}/esg`;
            const apiUrlTranslations = `${baseUrl}Fund/${researchId}/Translation`;
            const apiUrlFundUnderReviewDocument = `${baseUrl}Fund/${researchId}/UploadFile`;
            const formData = new FormData();
            let documentId = undefined;
            if (file && file.length > 0) {
                formData.append("file", file[0]);
            }
            setIsDialogOpenReview(false);
            handleOpenBackdrop();
            if (file?.length > 0) {
                try {
                    const response = await axios.post(apiUrlFundUnderReviewDocument, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${accessToken}`,
                        }
                    });
                    console.log('Success:', response.data);
                    console.log('Id:', response.data.data.id);
                    documentId = response.data.data.id;
                } catch (error) {
                    handleManageToast(
                        'error',
                        'Error with document'
                    );
                    console.error('Error submitting form:', error);
                    handleCloseBackdrop();
                    return;
                }
            }
            setSubmitting(true);
            const currentManager = watch('manager');
            const currentManagerId = currentManager && managerMap[currentManager] ? managerMap[currentManager] : null;
            const responses = [];
            try {
                const dataFund = {
                    id: researchId,
                    name: fundFamilyName,
                    view: view,
                    bkbRisikoklasse: bkbRisikoklasse,
                    bkbRetro: bkbRetro,
                    bkbEsg: bkbEsg,
                    bkbTyp: bkbTyp,
                    bkbQa: bkbQa,
                    blkbEsgKat: blkbEsgKat,
                    shkbGkNumber: shkbGkNumber,
                    slsStatus: slsStatus,
                    slsRisikotyp: slsRisikotyp,
                    slsFw: slsFw,
                    analystReport: handleTransformToBooleanValue(
                        belongsToInsightList as string
                    ),
                    applyFamily: applyAllFamilyFunds,
                    factSheetDate: factsheetDate,
                    startDate: dayjs(),
                    afbStatus_underReview: underReview,
                    isRestricted: restrictedToggle,
                    isAnalystSelection: analystSelectionToggle,
                    teamRating: teamStars,
                    investmentProcessRating: investmentProcessStars,
                    manager_Id: currentManagerId,
                    peerGroup_Id: idPeerGroup,
                    reviewComment: comment,
                    fundUploadFile_Id: documentId,
                    notes: notes
                };

                const languageId = handleGetLanguageIdFromSelect(
                    languagesValue as string
                );

                const languageItem = handleGetLanguageItem(
                    researchData?.translations as Translation[],
                    languageId as number
                );

                const dataTranslations = {
                    id: languageItem ? languageItem['id'] : newId,
                    fund_Id: researchId,
                    language_Id: languageId,
                    profile: 'string',
                    afbStatus: 'string',
                    fundDescription: fundDescription,
                    fundSources: assessment,
                    teamDescription: team,
                    investmentProcessDescription: investmentProcess,
                };

                esgData = flattenEsgData(esgData);

                const esgDataWithIDs = {
                    ...esgData,
                    id: esgDataId,
                    fundId: researchId
                };

                const results = await Promise.allSettled([
                    axios.put(apiUrlFund, dataFund, {headers: {Authorization: `Bearer ${accessToken}`}}),
                    axios.put(apiUrlFundEsg, esgDataWithIDs, {headers: {Authorization: `Bearer ${accessToken}`}}),
                    axios.post(apiUrlTranslations, dataTranslations, {headers: {Authorization: `Bearer ${accessToken}`}})
                ]);

                let conflictCount = 0;

                results.forEach(result => {
                    if (result.status === 'rejected' && result.reason?.response?.status === 409) {
                        conflictCount++;
                    } else if (result.status === 'rejected' && result.reason?.response?.status === 401) {
                        handleLogOut();
                        return;
                    } else if (result.status === 'rejected') {
                        handleManageToast('error', 'There was an unexpected error trying to save the data');
                    }
                });
                if (conflictCount === results.length) {
                    handleManageToast('success', 'No changes were made, data already up-to-date');
                } else if (conflictCount < results.length) {
                    handleManageToast('success', 'Data saved successfully');
                }

            } catch (error) {
                handleManageToast('error', 'An unexpected error occurred.');
            } finally {
                handleCloseBackdrop();
                setSubmitting(false);
                setRefetchData(prev => !prev);
            }
        },
        [
            researchData,
            researchId,
            baseUrl,
            handleCloseBackdrop,
            handleOpenBackdrop,
            handleManageToast,
            handleTransformToBooleanValue,
            handleGetIdPeerGroup,
            languagesValue,
            accessToken,
            setSubmitting,
            handleGetLanguageIdFromSelect,
            handleGetLanguageItem,
            handleLogOut,
            newId,
            managerMap
        ]
    );

    useEffect(() => {
        handleGetPeerGroup();
        handleGetAfbStatusGroup();
        handleGetLanguages();
    }, [
        handleGetPeerGroup,
        handleGetAfbStatusGroup,
        handleGetLanguages,
    ]);

    useEffect(() => {
        fetchResearchData(researchId as string);
    }, [researchId, refetchData]);
    useEffect(() => {
        fetchManagerData();
    }, [fetchManagerData]);

    useEffect(() => {
        if (researchData) {
            setValue('underReview', researchData?.afbStatus_underReview?.value);
            setValue('restrictedToggle', researchData?.isRestricted?.value);
            setValue('analystSelectionToggle', researchData?.isAnalystSelection?.value);
            setValue('applyAllFamilyFunds', researchData?.applyFamily?.value);
            setValue(
                'factsheetDate',
                dayjs(researchData?.factSheetDate?.value)
            );
            setValue(
                'belongsToInsightList',
                handleTransformBooleanValue(researchData?.analystReport?.value)
            );
            setValue(
                'peerGroupGeneralInfo',
                handleGetPeerGroupName(idPeerGroup ?? 0)
            );
            setValue('fundFamilyName', researchData?.name);
            setValue('view', researchData?.view?.value || '');
            setValue('bkbRisikoklasse', researchData?.bkbRisikoklasse?.value || '');
            setValue('bkbRetro', researchData?.bkbRetro?.value || '');
            setValue('bkbEsg', researchData?.bkbEsg?.value || '');
            setValue('bkbTyp', researchData?.bkbTyp?.value || '');
            setValue('bkbQa', researchData?.bkbQa?.value || '');
            setValue('blkbEsgKat', researchData?.blkbEsgKat?.value || '');
            setValue('shkbGkNumber', researchData?.shkbGkNumber?.value || '');
            setValue('slsStatus', researchData?.slsStatus?.value || '');
            setValue('slsRisikotyp', researchData?.slsRisikotyp?.value || '');
            setValue('slsFw', researchData?.slsFw?.value || '');

            setValue(
                'afbStatusGeneralInfo',
                handleGetAfbStatusName(researchData?.status_Id?.value)
            );
            setValue('teamStars', researchData?.teamRating?.value);
            setValue(
                'investmentProcessStars',
                researchData?.investmentProcessRating?.value
            );
            console.log(researchData)

            setValue(
                'manager',
                handleGetManagerName(researchData?.manager_Id)
            );

            setValue('comment', researchData?.reviewComment);

            setValue('notes', researchData?.notes?.value);
            setValue(
                'notesLastUpdate',
                dayjs(
                    researchData?.notes?.lastModification
                        ?.lastModifiedDate
                ).format('DD/MM/YYYY')
            );
            setValue(
                'notesLastUpdateName',
                researchData?.notes?.lastModification
                    ?.lastModifiedBy
            );

            if (researchData?.translations) {
                const languageId = handleGetLanguageIdFromSelect(
                    languagesValue as string
                );
                const languageItem = handleGetLanguageItem(
                    researchData.translations as Translation[],
                    languageId as number
                );

                if (languageItem) {
                    setValue(
                        'fundDescription',
                        languageItem?.fundDescription?.value
                    );

                    setValue(
                        'fundDescriptionLastUpdate',
                        dayjs(
                            languageItem?.fundDescription?.lastModification
                                ?.lastModifiedDate
                        ).format('DD/MM/YYYY')
                    );
                    setValue(
                        'fundDescriptionLastUpdateName',
                        languageItem?.fundDescription?.lastModification
                            ?.lastModifiedBy
                    );

                    setValue('team', languageItem?.teamDescription?.value);

                    setValue(
                        'teamLastUpdate',
                        dayjs(
                            languageItem?.teamDescription?.lastModification
                                ?.lastModifiedDate
                        ).format('DD/MM/YYYY')
                    );
                    setValue(
                        'teamLastUpdateName',
                        languageItem?.teamDescription?.lastModification
                            ?.lastModifiedBy
                    );

                    setValue('assessment', languageItem?.fundSources?.value);

                    setValue(
                        'assessmentLastUpdate',
                        dayjs(
                            languageItem?.fundSources?.lastModification
                                ?.lastModifiedDate
                        ).format('DD/MM/YYYY')
                    );
                    setValue(
                        'assessmentLastUpdateName',
                        languageItem?.fundSources?.lastModification
                            ?.lastModifiedBy
                    );

                    setValue(
                        'investmentProcess',
                        languageItem?.investmentProcessDescription?.value
                    );

                    setValue(
                        'investmentProcessLastUpdate',
                        dayjs(
                            languageItem?.investmentProcessDescription
                                ?.lastModification?.lastModifiedDate
                        ).format('DD/MM/YYYY')
                    );
                    setValue(
                        'investmentProcessUpdateName',
                        languageItem?.investmentProcessDescription
                            ?.lastModification?.lastModifiedBy
                    );
                }
            }
        }
    }, [
        setValue,
        researchData,
        handleTransformBooleanValue,
        handleGetPeerGroupName,
        handleGetAfbStatusName,
        handleGetLanguageIdFromSelect,
        handleGetLanguageItem,
        languagesValue,
        handleGetManagerName,
    ]);

    if (loading && !isDialogOpen) return <Loading/>;

    document.title = `AppHub - Dqr - Research Detail`;
    return (
        <>
            <Box
                onSubmit={handleSubmit(onSubmit)}
                component={isDialogOpen ? 'section' : 'form'}
                width={'100%'}
            >
                <Grid container alignItems={'flex-start'} gap={3}>
                    <TabComponent
                        tab={tab}
                        handleChangeTab={handleChangeTab}
                        tabsArray={tabsArray}
                    />

                    <Grid item sx={{width: '200px'}}>
                        <OptionSelect
                            control={control}
                            data={languagesData}
                            name='languages'
                            labelName='Languages'
                            label
                        />
                    </Grid>
                </Grid>

                <Grid container flexDirection={'column'} gap={3}>
                    <Grid item>{handleChangeSectionByTab()}</Grid>
                </Grid>

                <Divider/>
                <Grid
                    container
                    item
                    justifyContent={'flex-end'}
                    sx={{margin: '17px 0'}}
                    gap={2}
                >
                    <CustomButton
                        onClick={handleOpenDialogConditional}
                        color='success'
                        variant='contained'
                        size='large'
                        disabled={submitting}
                        text={'Save'}
                    />
                </Grid>
                <Toast
                    handleCloseToast={handleCloseToast as () => void}
                    openToast={openToast as boolean}
                    severity={alertSeverity}
                    message={alertMessage}
                />

                <SimpleBackdrop
                    openBackdrop={openBackdrop as boolean}
                />
            </Box>

            <CustomDialog
                submitForm={handleSubmit(handleCreateISIN)}
                isAForm
                open={isDialogOpen as boolean}
                title='New ISIN'
                text=''
                onClose={handleCloseDialog}
                actions={
                    loading ? (
                        <></>
                    ) : (
                        <>
                            <CustomButton
                                onClick={handleCloseDialog}
                                text='CANCEL'
                            />
                            <CustomButton text='CREATE' type='submit'/>
                        </>
                    )
                }
            >
                <Grid
                    container
                    flexDirection={'column'}
                    gap={2}
                    marginTop={'10px'}
                >
                    {loading ? (
                        <Box display='flex' justifyContent='center'>
                            <CircularProgress/>
                        </Box>
                    ) : (
                        <Grid item>
                            <TextInput
                                error={!!errors.fundIsin}
                                ruleRequired
                                name='fundIsin'
                                control={control}
                                labelName='ISIN'
                            />
                        </Grid>
                    )}
                </Grid>
            </CustomDialog>

            <CustomDialog
                open={isDialogOpenReview}
                title='Comment'
                text=''
                onClose={handleCloseDialogReview}
                actions={
                    loading ? (
                        <></>
                    ) : (
                        <>
                            <CustomButton
                                onClick={handleCloseDialogReview}
                                text='CANCEL'
                            />
                            <CustomButton text='SAVE' type='button' onClick={handleSubmit(onSubmit)}/>
                        </>
                    )
                }
            >
                <Grid
                    container
                    flexDirection={'column'}
                    gap={2}
                    marginTop={'10px'}
                >
                    {loading ? (
                        <Box display='flex' justifyContent='center'>
                            <CircularProgress/>
                        </Box>
                    ) : (
                        <Grid item>
                            <TextInput
                                ruleRequired
                                name='comment'
                                control={control}
                                labelName='Comment'
                            />
                            <Typography variant="body2" style={{ marginTop: '8px' }}>
                                {new Date().toLocaleDateString()}
                            </Typography>
                            <Controller
                                name="file"
                                control={control}
                                defaultValue={[]}
                                render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
                                    <input
                                        type="file"
                                        onChange={e => onChange(e.target.files)}
                                        onBlur={onBlur}
                                        style={{marginTop: '8px'}}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                </Grid>
            </CustomDialog>
        </>
    );
}

export default ResearchDetail;
