export const columns = [
    {
        field: 'name',
        headerName: 'Name',
    },
    {
        field: 'isin',
        headerName: 'ISIN',
    },
];
