import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Grid} from '@mui/material';
import CustomTable from '../../../../components/custom-table/custom-table';
import {ISIN} from '../../../../types/research-detail';
import InfoPage from '../../../info/info-page';
import dayjs from 'dayjs';
import {columns} from '../../../../data/columns/isins';
import CustomButton from '../../../../components/custom-button/custom-button';
import TextInput from '../../../../components/text-input/text-input';
import {Control} from 'react-hook-form';
import {IFormInputs} from '../../../../types/shared';
import CustomDialog from '../../../../components/custom-dialog/custom-dialog';
import {GridRowSelectionModel} from '@mui/x-data-grid';

interface Props {
    readonly data: ISIN[];
    readonly searchValue?: string;
    readonly control: Control<IFormInputs>;
    readonly selected: GridRowSelectionModel;
    readonly setSelected: React.Dispatch<
        React.SetStateAction<GridRowSelectionModel | []>
    >;
}

function Isins({
                   data,
                   searchValue,
                   control,
                   selected,
                   setSelected,
               }: Props) {
    const [isinfilteredArray, setIsinFilteredArray] = useState<ISIN[]>([]);

    const rows = useMemo(() => {
        return isinfilteredArray?.map(
            ({isin, name, id}) => {
                return {
                    id: id,
                    isin: isin,
                    name: name,
                };
            }
        );
    }, [isinfilteredArray]);

    const handleFilterIsin = useCallback(() => {
        if (!searchValue) {
            setIsinFilteredArray(data);
            return;
        }
        const searchValueLower = searchValue?.toLowerCase().replace(/\s/g, '');

        const filtered = data?.filter((item) => {
            const originalNameLower = item.isin
                .toLowerCase()
                .replace(/\s/g, '');
            const isinNameLower = item.name
                .toLowerCase()
                .replace(/\s/g, '');

            return (
                originalNameLower.includes(searchValueLower) ||
                isinNameLower.includes(searchValueLower)
            );
        });

        setIsinFilteredArray(filtered);
    }, [data, searchValue, setIsinFilteredArray]);

    useEffect(() => {
        handleFilterIsin();
    }, [handleFilterIsin]);

    useEffect(() => {
        setSelected([]);
    }, [setSelected]);

    return (
        <Grid container gap={2} flexDirection='column'>
            <Grid container gap={2} marginTop={'20px'}>
                <Grid item style={{flexGrow: 1}}>
                    <TextInput
                        name='searchValue'
                        control={control}
                        labelName={'Search by isin or author '}
                    />
                </Grid>
            </Grid>
            {isinfilteredArray.length > 0 ? (
                <Grid item container gap={2}>
                    {isinfilteredArray && isinfilteredArray.length > 0 && (
                        <Grid
                            item
                            sx={{
                                width: '100%',
                            }}
                        >
                            <CustomTable
                                useCheckbox={false}
                                selected={selected}
                                setSelected={setSelected}
                                columns={columns}
                                rows={rows}
                                isSelectable
                            />
                        </Grid>
                    )}
                </Grid>
            ) : (
                <InfoPage message={'The are no ISINS to show'}/>
            )}
        </Grid>
    );
}

export default Isins;
