export const columns = [
    { field: 'reviewComment', headerName: 'Review Comment' },
    {
        field: 'isUnderReview',
        headerName: 'Under Review',
    },
    {
        field: 'isRestricted',
        headerName: 'Restricted',
    },
    {
        field: 'isAnalystSelection',
        headerName: 'Analyst Selection'
    },
    {
        field: 'authorName',
        headerName: 'Author',
    },
    {
        field: 'createdDate',
        headerName: 'Created Date',
    },
    {
        field: 'document',
        headerName: 'Document',
    },
    {
        field: 'delete',
        headerName: 'Delete',
    },
];
